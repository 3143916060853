<template>
    <div class="min_ticket">
        <div class="rest">
            <div class="text">本月剩余月票</div>
            <div class="numbers">{{ $store.state.money_info.month_ticket_num }}</div>
            <div><a href="javascript:;" @click="goGuide">如何获取推荐票?</a></div>
        </div>
        <el-tabs type="border-card" v-model="type">
            <el-tab-pane label="消耗记录" name="2">
                <ticketList @refreshTicketList="refreshTicketList" :list="list" type="2" :total="page1.total"></ticketList>
            </el-tab-pane>
            <el-tab-pane label="获取记录" name="1">
                <ticketList @refreshTicketList="refreshTicketList" :list="list2" type="1" :total="page2.total"></ticketList>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getTicketRecord } from "@/https/mine_center.js";
import ticketList from './components/ticket_list';
export default {
    name: "ticket",
    components: {
        ticketList
    },
    data(){
        return {
            type: '2', //1获取 2消费 0全部
            list: [],//消费
            list2: [],//获取
            page1: {
                total: 0,
                page: 1,
                limit: 10
            },
            page2: {
                total: 0,
                page: 1,
                limit: 10
            }
        }
    },
    mounted() {
        this.getData({
            type: '1',
            page: 1,
            limit: 10,
        });
        this.getData({
            type: '2',
            page: 1,
            limit: 10,
        });
        this.$store.dispatch('updateMoneyinfo');
    },
    methods: {
        goGuide(){
            this.$router.push({
                path: "/guid",
                query: {
                    id: 'guid-ticket',
                },
            });
        },
        getData(params){
            getTicketRecord(params).then(res=>{
                if(res.code === 0){
                    let _list = res.data.data || [];
                    let list = _list.map(item=>{
                        return {
                            ...item,
                            source_type_text: ['保底', '消费获得', '打赏获得', '投票', '失效'][item.source_type-1]
                        }
                    });
                    if(params.type === '2'){
                        this.list = list;
                        this.page1.total = res.data.total || 0;
                    }else{
                        this.list2 = list;
                        this.page2.total = res.data.total || 0;
                    }
                }
            })
        },
        refreshTicketList(params){
            if(this.type === '2'){
                this.list = [];
            }else{
                this.list2 = [];
            }
            this.getData({
                ...params,
                type: this.type
            })
        }
    }
}
</script>

<style lang="scss">
.min_ticket{
    .rest{
        display: flex;
        padding: 51px 0;
        border-radius: 5px;
        .text{
            padding-left: 98px;
            font-size: 20px;
            font-weight: 400;
            line-height: 15px;
            color: #333333;
            flex-grow: 2;
        }
        .numbers{
            padding-right: 70px;
        }
        a{
            padding: 0 32px;
            font-size: 16px;
            font-weight: 400;
            line-height: 15px;
            color: #409EFF;
        }

    }

    .el-tabs--border-card{
        margin-top: 10px;
        border: none;
        box-shadow: none;
        background-color: #FFF;
        border-radius: 5px;
        overflow: hidden;
        .el-tabs__header{
            background: #F5F7FA;
            border: none;
        }
        .el-tabs__item{
            height: 50px;
            border: none;
            font-size: 16px;
            font-weight: bold;
            line-height: 50px;
            color: #333;
        }
    }

    .el-table__header-wrapper{
        th{
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            color: #333333;;
        }
    }
    .el-table__body-wrapper{
        td{
            text-align: center;
        }
    }
    .el-pagination{
        margin-top: 25px;
        text-align: center;
    }
}
</style>