<template>
    <div class="ticket_list">
        <el-table
            :data="list"
            style="width: 100%">
            <el-table-column
                prop="created_at"
                label="时间"
                width="180">
            </el-table-column>
            <el-table-column
                prop="ticket_num"
                label="数量"
                width="180">
            </el-table-column>
            <el-table-column
                prop="source_type_text"
                label="类型">
            </el-table-column>
            <el-table-column
                v-if="type !== '1'"
                prop="book.name"
                label="对象">
            </el-table-column>
        </el-table>

        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "ticket_list",
    props: {
        list: Array,
        type: String,
        total: Number
    },
    data(){
        return {
            page: 1,
            limit: 10
        }
    },
    methods: {
        handleSizeChange(val) {
            this.page = 1;
            this.limit = val;
            this.$emit('refreshTicketList', {
                page: this.page,
                limit: this.limit,
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            this.$emit('refreshTicketList', {
                page: this.page,
                limit: this.limit,
            });
        }
    }
}
</script>

<style lang="scss">

</style>